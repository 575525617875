export function getNameAbbreviation(name: string = ''): string {
  return name
    .split(' ')
    .map((part) => {
      const firstLetter = part.split('').find((char) => /[a-zA-Zа-яА-Я]/.test(char));
      return firstLetter?.toUpperCase() || '';
    })
    .slice(0, 2)
    .join('');
}
