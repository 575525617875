export class Speaker {
  id: string;
  label: string;
  muted: boolean;

  constructor(id: string, label: string) {
    this.id = id;
    this.label = label;
    this.muted = false;
  }

  getId(): string {
    return this.id;
  }

  getLabel(): string {
    return this.label;
  }
}

// Temporary solution based on current Mind SDK implementation.
// This workaround will be replaced by direct integration with Mind SDK
// once the necessary functionality is available in the SDK.
export class SpeakerRegistry {
  private speakers: Speaker[] = [];
  private listener: { onDeviceRegistryChanged: () => void } | null = null;

  constructor() {
    navigator.mediaDevices.addEventListener('devicechange', () => this.update());
  }

  getSpeakers(): Speaker[] {
    return this.speakers;
  }

  setListener(listener: { onDeviceRegistryChanged: () => void } | null): void {
    this.listener = listener;
  }

  async update(): Promise<void> {
    const devices = await navigator.mediaDevices.enumerateDevices();
    let notifyListener = false;

    for (let i = this.speakers.length - 1; i >= 0; i--) {
      const speaker = this.speakers[i];
      if (!devices.find((device) => device.kind === 'audiooutput' && device.deviceId === speaker.getId())) {
        this.speakers.splice(i, 1);
        notifyListener = true;
      }
    }

    if (this.speakers.length === 0) {
      this.speakers.push(new Speaker('default', 'Default Speaker'));
    }

    devices.forEach((device) => {
      if (device.kind === 'audiooutput') {
        if (device.label !== '' && device.label !== 'default') {
          if (!this.speakers.find((s) => s.getId() === device.deviceId)) {
            this.speakers.push(new Speaker(device.deviceId, device.label));
            notifyListener = true;
          }
        }
      }
    });

    if (this.listener && notifyListener) {
      this.listener.onDeviceRegistryChanged();
    }
  }
}
