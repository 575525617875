import { ParticipantsLayout } from '@/model/ParticipantsLayout';
import { create, StateCreator } from 'zustand';
import { createJSONStorage, persist, PersistOptions } from 'zustand/middleware';

export interface SettingsStore {
  selectedMicrophoneId: string | null;
  setMicrophoneId: (microphoneId: string | null) => void;

  selectedCameraId: string | null;
  setCameraId: (cameraId: string | null) => void;

  selectedSpeakerId: string | null;
  setSpeakerId: (speakerId: string | null) => void;

  layout: ParticipantsLayout;
  setLayout: (layoutType: ParticipantsLayout) => void;
}

type SettingsStorePersist = (
  config: StateCreator<SettingsStore>,
  options: PersistOptions<SettingsStore>
) => StateCreator<SettingsStore>;

export const useSettingsStore = create<SettingsStore>(
  (persist as SettingsStorePersist)(
    (set) => ({
      selectedMicrophoneId: null,
      setMicrophoneId: (microphoneId: string | null) => set({ selectedMicrophoneId: microphoneId }),

      selectedCameraId: null,
      setCameraId: (cameraId: string | null) => set({ selectedCameraId: cameraId }),

      selectedSpeakerId: null,
      setSpeakerId: (speakerId: string | null) => set({ selectedSpeakerId: speakerId }),

      layout: ParticipantsLayout.GRID,
      setLayout: (layoutType: ParticipantsLayout) => set({ layout: layoutType }),
    }),
    {
      name: 'settings',
      storage: createJSONStorage(() => localStorage),
    }
  )
);
