import { FC, PropsWithChildren } from 'react';

type MessageSheetProps = PropsWithChildren & {
  message: string | string[];
};

const MessageSheet: FC<MessageSheetProps> = ({ message }) => {
  const messages: string[] = Array.isArray(message) ? message : [message];
  return (
    <div className="flex flex-col gap-2 h-full w-screen justify-center items-center text-3xl text-muted-foreground text-center overflow-y-auto">
      {messages?.map((msg, i) => <div key={i}>{msg}</div>)}
    </div>
  );
};

export default MessageSheet;
