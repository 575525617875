import { DeviceRegistryListener } from 'mind-sdk-web';
import { DeviceService } from '@/service/DeviceService';
import { useConferenceStore } from '@/store/ConferenceStore';

export class DeviceRegistryEventListener implements DeviceRegistryListener {
  private readonly _deviceService: DeviceService;

  constructor(deviceService: DeviceService) {
    this._deviceService = deviceService;
  }

  async onDeviceRegistryChanged(): Promise<void> {
    const session = useConferenceStore.getState().session;
    if (session) {
      await this._deviceService.setupMediaDevices();
    }
  }
}
