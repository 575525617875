import AuthGuard from '@/components/AuthGuard.tsx';
import ConferenceErrorBoundary from '@/components/ConferenceErrorBoundary.tsx';
import MessageSheet from '@/components/MessageSheet.tsx';
import { Toaster } from '@/components/ui/sonner.tsx';
import { createConferenceSDK } from '@/lib/conference-sdk.ts';
import { getAuthService, useAuthService } from '@/service/AuthService.ts';
import { getConferenceService, initializeConferenceService } from '@/service/ConferenceService.ts';
import { initializeDeviceService } from '@/service/DeviceService.ts';
import { lazy, Suspense, useCallback, useEffect, useRef, useState } from 'react';
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from 'react-router-dom';
import './index.css';
import HomePage from './pages/HomePage.tsx';

const AuthPage = lazy(() =>
  import('@/pages/AuthPage.tsx').catch((e) => {
    console.error(e);
    return { default: () => <MessageSheet message="Auth page not found" /> };
  })
);
const HistoryPage = lazy(() =>
  import('@/pages/HistoryPage.tsx').catch((e) => {
    console.error(e);
    return { default: () => <MessageSheet message="History page not found" /> };
  })
);
const VersionPage = lazy(() =>
  import('@/pages/VersionPage.tsx').catch((e) => {
    console.error(e);
    return { default: () => <MessageSheet message="Version page not found" /> };
  })
);
const ConferencePage = lazy(() =>
  import('@/pages/ConferencePage.tsx').catch((e) => {
    console.error(e);
    return { default: () => <MessageSheet message="Conference page not found" /> };
  })
);

const DEFAULT_FALLBACK = <MessageSheet message="Loading..." />;

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/">
      <Route index element={<HomePage />} />

      <Route
        path="auth"
        element={
          <Suspense fallback={DEFAULT_FALLBACK}>
            <ConferenceErrorBoundary>
              <AuthPage />
            </ConferenceErrorBoundary>
          </Suspense>
        }
      />

      <Route element={<AuthGuard />}>
        <Route
          path="history"
          element={
            <Suspense fallback={DEFAULT_FALLBACK}>
              <ConferenceErrorBoundary>
                <HistoryPage />
              </ConferenceErrorBoundary>
            </Suspense>
          }
        />
      </Route>

      <Route
        path=":roomId"
        element={
          <Suspense fallback={DEFAULT_FALLBACK}>
            <ConferenceErrorBoundary>
              <ConferencePage />
            </ConferenceErrorBoundary>
          </Suspense>
        }
      />

      <Route
        path="version"
        element={
          <Suspense fallback={DEFAULT_FALLBACK}>
            <ConferenceErrorBoundary>
              <VersionPage />
            </ConferenceErrorBoundary>
          </Suspense>
        }
      />
    </Route>
  )
);

function App() {
  const authService = useAuthService();
  const timeoutIdRef = useRef<NodeJS.Timeout | null>(null);
  const [servicesInitialized, setServicesInitialized] = useState(false);

  const scheduleTokenRefresh = useCallback((): void => {
    const expIn: number | null = authService.getAccessTokenExpiresIn();
    if (expIn) {
      const timeout = expIn > 5000 ? expIn - 5000 : 0; // 5 seconds before expiry
      timeoutIdRef.current = setTimeout(async () => {
        await authService.refresh();
        scheduleTokenRefresh();
      }, timeout);
    }
  }, [authService]);

  const initializeServices = useCallback(async () => {
    const sdk = await createConferenceSDK();
    initializeDeviceService(sdk);
    initializeConferenceService(sdk);
    setServicesInitialized(true);
  }, []);

  useEffect(() => {
    (async () => {
      scheduleTokenRefresh();
      await initializeServices();

      return () => timeoutIdRef.current && clearTimeout(timeoutIdRef.current);
    })();
  }, [initializeServices, scheduleTokenRefresh]);

  // Reload the page when the user returns from another tab
  useEffect(() => {
    window.onpageshow = function (event) {
      if (event.persisted) {
        window.location.reload();
      }
    };

    const handleDOMContentLoaded = async () => {
      const authService = getAuthService();
      await authService.getUser();
    };
    if (document.readyState === 'loading') {
      document.addEventListener('DOMContentLoaded', handleDOMContentLoaded);
    } else {
      handleDOMContentLoaded().catch(console.error);
    }

    return () => {
      document.removeEventListener('DOMContentLoaded', handleDOMContentLoaded);
    };
  }, []);

  // Clean up on conference leave
  useEffect(() => {
    const handleBeforeUnload = () => {
      const conferenceService = getConferenceService();
      if (conferenceService) {
        conferenceService.closeSession();
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, []);

  // Handle page visibility changes
  useEffect(() => {
    const handleVisibilityChange = async () => {
      if (document.visibilityState === 'visible') {
        const conferenceService = getConferenceService();
        if (!conferenceService) {
          await initializeServices();
        }
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => document.removeEventListener('visibilitychange', handleVisibilityChange);
  }, [initializeServices]);

  if (!servicesInitialized) {
    return <MessageSheet message="Initializing..." />;
  }

  return (
    <>
      <RouterProvider router={router} />
      <Toaster
        position="top-right"
        theme="light"
        toastOptions={{
          closeButton: true,
          classNames: {
            closeButton: 'bg-white absolute top-2 right-2 left-auto transform-none border-none',
          },
        }}
      />
    </>
  );
}

export default App;
